var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.chartSales,"options":_vm.chartOptions}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('GChart',{attrs:{"type":"PieChart","data":_vm.chartInventory,"options":_vm.chartOptions}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Ventas")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_sales,"items":_vm.sales,"items-per-page":5,"item-key":"category","mobile-breakpoint":"0","calculate-widths":"","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toLocaleString(2))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.discount).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("sales", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("sales", "discount")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("sales", "price")))])])])],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%","color":"deep-orange lighten-4"}},[_c('v-card-title',[_vm._v("Inventario")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_inventory,"items":_vm.inventory,"items-per-page":5,"item-key":"category","mobile-breakpoint":"0","calculate-widths":"","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toLocaleString(2))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.discount).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("inventory", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("inventory", "price")))])])])],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%","color":"cyan lighten-5"}},[_c('v-card-title',[_vm._v("Formas de pago")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_payments,"items":_vm.payments,"items-per-page":5,"item-key":"label","mobile-breakpoint":"0","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString(2))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.value).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("payments", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("payments", "value")))])])])],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%","color":"lime lighten-5"}},[_c('v-card-title',[_vm._v("Eventos")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_events,"items":_vm.events,"items-per-page":5,"item-key":"label","mobile-breakpoint":"0","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString(2))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.price).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("events", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("events", "price")))])])])],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%","color":"green lighten-5"}},[_c('v-card-title',[_vm._v("Ingreso")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_events,"items":_vm.cin,"items-per-page":5,"item-key":"label","mobile-breakpoint":"0","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString("es"))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.doc_val).toLocaleString("es"))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("cin", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("cin", "doc_val")))])])])],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{attrs:{"height":"100%","color":"red lighten-5"}},[_c('v-card-title',[_vm._v("Egresos")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_events,"items":_vm.cout,"items-per-page":5,"item-key":"label","mobile-breakpoint":"0","sort-by":"category","loading-text":"Cargando ...","dense":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString("es"))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.doc_val).toLocaleString("es"))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.sumTable("cout", "quantity")))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("cout", "doc_val")))])])])],2)],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Resumen")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header_resume,"items":_vm.resume,"item-key":"label","sort-by":"label","mobile-breakpoint":"0","loading-text":"Cargando ...","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.quantity).toLocaleString(2))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.value).toLocaleString(2))+" ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"title"},[_vm._v("Total")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s("$ " + _vm.sumTable("resume", "value")))])])])],2)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading_status),callback:function ($$v) {_vm.loading_status=$$v},expression:"loading_status"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pa-6"},[_c('p',[_vm._v("Proceso")]),_c('p',[_vm._v(_vm._s(this.lStatus))]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }